import { useEffect } from "react";
import { Link } from "react-router-dom";

import service from "../../images/services/service.png";
import service1 from "../../images/services/service1.png";
import service2 from "../../images/services/service2.png";
import service3 from "../../images/services/service3.png";

const Services = (props) => {

  useEffect(() => {
    props.setHeaderTitle("Services")
    props.setFooterActive(2);
  }, [])
  return (
    <>
      <div className="container serviceSection">
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Services</li>
            </ul>
          </div>
        </div>
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <h1>Services</h1>
          </div>
        </div>
        <div className="row items">
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service} alt="Collection" />
              <p>Phone Maintence</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service1} alt="Collection" />
              <p>Broken Screen
                Replacement</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service2} alt="Collection" />
              <p>Software Updates</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service3} alt="Collection" />
              <p>Customize Product</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service} alt="Collection" />
              <p>Phone Maintence</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service1} alt="Collection" />
              <p>Broken Screen
                Replacement</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service2} alt="Collection" />
              <p>Software Updates</p>
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link to={"/ServiceDetails"}>
              <img src={service3} alt="Collection" />
              <p>Customize Product</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Services;
