import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import "./css/mobile.css";
import "./css/responsive.css";
import AppRoute from "./Variables/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./pages/Layout/Header";
import Footer from "./pages/Layout/Footer";
import { Global } from "./Variables/Global";
import Util from "./Classes/Util";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";


function App() {
  const [customerDetail, setCustomerDetail] = useState({
    FirstName: "",
    LastName: "",
  });
  const [subscribe, setSubscribe] = useState(false)
  const [cartCount, setCartCount] = useState(0);
  const [wishListCount, setWishListCount] = useState(0);
  const [cartItem, setCartItem] = useState([]);
  const [wishListItem, setWishListItem] = useState([]);
  const [crossSell, setCrossSell] = useState([])
  const [upSell, setUpSell] = useState([])
  const [headerTitle, setHeaderTitle] = useState("")
  const [footerActive, setFooterActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomer = (ID) => {
    const token = Cookies.get("CustomerToken");
    fetch(Global.API_URL + "Customer/GetCustomerDetails/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setCustomerDetail(Result);
      });
  };

  const checkIfCustomerLoggedIn = () => {
    setIsLoading(true);
    if (localStorage.getItem("PMToken") !== null) {
      var token = Util.decrypt(localStorage.getItem("PMToken"));
      var ID = Util.decrypt(localStorage.getItem("Number"));
      fetch(Global.API_URL + "Customer/CheckCustomerLoginToken", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: ID,
          customerToken: token,
        }),
      })
        .then((Response) => {
          if (Response.ok) {
            return Response.json();
          } else {
            localStorage.clear();
            checkIfCustomerLoggedIn();
          }
        })
        .then((Result) => {
          fetchCustomer(ID);
          if (localStorage.getItem("Cart")) {
            GetCartItem(Util.decrypt(localStorage.getItem("Cart")));
          }
          if (localStorage.getItem("WishList")) {
            GetWishListItem(Util.decrypt(localStorage.getItem("WishList")));
          }
        });
    } else {
      localStorage.clear()
      if (sessionStorage.getItem("Guest") === null) {
        // CreateGuest();
      } else {
        var GuestID = Util.decrypt(sessionStorage.getItem("Guest"));
        // GetGuestWishList(GuestID);
        // GetGuestCart(GuestID);
      }
    }
    setIsLoading(false);
  };

  const GetGuestWishList = (ID) => {
    const offset = new Date().getTimezoneOffset();
    fetch(Global.API_URL + "Wishlist/GetGuestWishlist/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        sessionStorage.setItem("GuestWishList", Util.encrypt(Result));

        GetWishListItem(Result);
      });
  };
  // const GetGuestCart = (ID) => {
  //   const offset = new Date().getTimezoneOffset();
  //   fetch(Global.API_URL + "Cart/GetGuestCart/" + ID, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((Response) => {
  //       if (Response.ok) {
  //         return Response.json();
  //       }
  //     })
  //     .then((Result) => {
  //       sessionStorage.setItem("GuestCart", Util.encrypt(Result));
  //       GetCartItem(Result);
  //     });
  // };
  const GetWishListItem = async (ID) => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    const token = Cookies.get("CustomerToken");
    fetch(Global.API_URL + "Wishlist/GetWishistItems/" + ID + "/" + offset + "/" + 125 + "/" + groupId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        setWishListCount(Result.Count);
        setWishListItem(Result.WishlistItems);
      });
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId

       
    //   })
  };
  const GetCartItem = async (ID) => {
    var groupId;
    if (Cookies.get("WishList")) {
      groupId = 4
    } else {
      groupId = 3
    }
    const token = Cookies.get("CustomerToken");
    const offset = new Date().getTimezoneOffset();
    fetch(Global.API_URL + "Cart/GetCartItems/" + ID + "/" + offset + "/" + groupId + "/" + 125, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        setCartCount(Result.Count);
        setCartItem(Result.CartItems);
        // setCrossSell(Result.CrossAndUpsell.CrossSells)
        // setUpSell(Result.CrossAndUpsell.UpSells)
      });
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
      
    //   })
  };


  useEffect(() => {
  //   if (window.location.protocol == 'http:') {
      
  //     console.log("you are accessing us via "
  //         + "an insecure protocol (HTTP). "
  //         + "Redirecting you to HTTPS.");
            
  //     window.location.href =
  //         window.location.href.replace(
  //                 'http:', 'https:');
  // }
    // checkIfCustomerLoggedIn();
  }, []);
  return (
    <BrowserRouter>
      <Header
        customerDetail={customerDetail}
        setCustomerDetail={setCustomerDetail}
        wishListCount={wishListCount}
        cartCount={cartCount}
        setCartCount={setCartCount}
        setWishListCount={setWishListCount}
        cartItem={cartItem}
        setCartItem={setCartItem}
        setWishListItem={setWishListItem}
        wishListItem={wishListItem}
        subscribe={subscribe}
        setSubscribe={setSubscribe}
        upSell={upSell}
        crossSell={crossSell}
        headerTitle={headerTitle}
      />
      {
        isLoading ? (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <AppRoute
        customerDetail={customerDetail}
        setCustomerDetail={setCustomerDetail}
        wishListCount={wishListCount}
        cartCount={cartCount}
        setCartCount={setCartCount}
        setWishListCount={setWishListCount}
        cartItem={cartItem}
        setCartItem={setCartItem}
        setWishListItem={setWishListItem}
        wishListItem={wishListItem}
        subscribe={subscribe}
        setSubscribe={setSubscribe}
        upSell={upSell}
        crossSell={crossSell}
        headerTitle={headerTitle}
        setHeaderTitle={setHeaderTitle}
        footerActive={footerActive}
        setFooterActive={setFooterActive}
      />
        )
       }
      
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Footer
        customerDetail={customerDetail}
        setCustomerDetail={setCustomerDetail}
        wishListCount={wishListCount}
        cartCount={cartCount}
        setCartCount={setCartCount}
        setWishListCount={setWishListCount}
        cartItem={cartItem}
        setCartItem={setCartItem}
        setWishListItem={setWishListItem}
        wishListItem={wishListItem}
        subscribe={subscribe}
        setSubscribe={setSubscribe}
        upSell={upSell}
        crossSell={crossSell}
        footerActive={footerActive}
      />
    </BrowserRouter>
  );
}

export default App;
