
import { Link, useParams } from "react-router-dom";
import addcart from "../../images/icons/addcart.png";
import product from "../../images/products/product.png";
import product1 from "../../images/products/product8.png";
import product2 from "../../images/products/product9.png";
import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";
import { Accordion } from 'react-bootstrap'
import { useEffect } from "react";

const DealBundles = (props) => {
    useEffect(() => {
        props.setHeaderTitle("Deal Bundles")
        props.setFooterActive(2);
    }, [])
    const pId = useParams();
    return (
        <>
            <div className="container productsList Deals">
                <div className="row d-none d-md-block">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>Deal Bundles</li>
                        </ul>
                    </div>
                </div>
                <div className="row d-none d-md-block">
                    <div className="col-12 col-md-12">
                        <h1>Deal Bundles</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3 filterBox d-none d-md-block">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    Item Type
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>1</li>
                                        <li>2</li>
                                        <li>3</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="item">
                                    <div className="row">
                                        <div className="col-6">
                                            <img src={product} alt="Product1" />
                                        </div>
                                        <div className="col-6">
                                            <img src={product2} alt="Product2" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="row position-relative">
                                            <div className="col-6">
                                                <h5>Choetech Portable Power Station 300W</h5>
                                                <p className="sub">Playmegastore</p>
                                                <span className="plus">+</span>
                                            </div>
                                            <div className="col-6">
                                                <h5>Choetech Portable Power Station 300W</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-end">
                                                <button className="btnWishList">
                                                    <img
                                                        src={wishListIcon}
                                                        alt="WishList"
                                                        style={{ display: "none" }}
                                                    ></img>
                                                    <img src={activeWishListIcon} alt="In WishList"></img>
                                                </button>
                                                <span className="off">-12%</span>
                                                <span className="old">Was: <strike> $1,510.00</strike></span>
                                                <span className="sale price">
                                                    $1,360.00{" "}
                                                    <button>
                                                        <img src={addcart} alt="Add to Cart"></img>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="item">
                                    <div className="row">
                                        <div className="col-6">
                                            <img src={product} alt="Product1" />
                                        </div>
                                        <div className="col-6">
                                            <img src={product2} alt="Product2" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="row position-relative">
                                            <div className="col-6">
                                                <h5>Choetech Portable Power Station 300W</h5>
                                                <p className="sub">Playmegastore</p>
                                                <span className="plus">+</span>
                                            </div>
                                            <div className="col-6">
                                                <h5>Choetech Portable Power Station 300W</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-end">
                                                <button className="btnWishList">
                                                    <img
                                                        src={wishListIcon}
                                                        alt="WishList"
                                                        style={{ display: "none" }}
                                                    ></img>
                                                    <img src={activeWishListIcon} alt="In WishList"></img>
                                                </button>
                                                <span className="off">-12%</span>
                                                <span className="old">Was: <strike> $1,510.00</strike></span>
                                                <span className="sale price">
                                                    $1,360.00{" "}
                                                    <button>
                                                        <img src={addcart} alt="Add to Cart"></img>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DealBundles;
