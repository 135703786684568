import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const ServiceDetails = (props) => {
    useEffect(() => {
        props.setHeaderTitle("Service Details")
        props.setFooterActive(2);
        window.scrollTo(0, 0);
      }, [])
    return (
        <>
            <div className='container mt-60 Sdetails'>
                <div className="row d-none d-md-block">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li><Link to="/Services">Services /</Link></li>
                            <li>Phone Maintenance</li>
                        </ul>
                    </div>
                </div>
                <div className='row mb-80 d-none d-md-block'>
                    <div className='col-12'>
                        <label className='mainTitle'>Phone Maintenance</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 col-lg-9 Profile'>
                        <div className='infoBox mt-0'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='title'>1 Information</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Full Name:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <input type="text" className='inputBox' placeholder='First Name' />
                                        </div>
                                        <div className='col-6'>
                                            <input type="text" className='inputBox' placeholder='Last Name' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 col-md-3'>
                                            <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                                            <label for="mr" className='checkLabel ms-2'>Mr.</label>
                                        </div>
                                        <div className='col-6 col-md-3'>
                                            <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                                            <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Phone Type</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    {/* <input type="text" className='inputBox' placeholder='Type Here' /> */}
                                    <select className='inputBoxSelect'>
                                        <option className='info' selected disabled>-- Choose a Type --</option>
                                        <option className='info'>Apple</option>
                                        <option className='info'>Android</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Issue Info:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <div className='col-12'>
                                        {/* <textarea placeholder='Type Address' className='textareaBox' cols="4" rows="3" /> */}
                                        <input type="text" className='inputBox' placeholder='Type Here' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Extra Info:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <div className='col-12'>
                                        {/* <textarea placeholder='Type Address' className='textareaBox' cols="4" rows="3" /> */}
                                        <input type="text" className='inputBox' placeholder='Type Here' />
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Pick Up At:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <select className='inputBoxSelect'>
                                                <option className='info' selected disabled>Date</option>
                                                <option className='info'>1</option>
                                            </select>
                                        </div>
                                        <div className='col-6'>
                                            <select className='inputBoxSelect'>
                                                <option className='info' selected disabled>Time</option>
                                                <option className='info'>1</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='title'>2  Pick Up Address</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Address Name:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Country:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <select className='inputBoxSelect'>
                                        <option className='info' selected disabled>-- Choose a Country --</option>
                                        <option className='info'>Country</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>City:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <select className='inputBoxSelect'>
                                        <option className='info' selected disabled>-- Choose a Country --</option>
                                        <option className='info'>Country</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Zip Code:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Postal Code:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-md-3'>
                                    <label className='infoTitle'>Address:</label>
                                </div>
                                <div className='col-8 col-md-9'>
                                    <textarea placeholder='Type Address' className='textareaBox' cols="4" rows="3" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 text-end'>
                                    <button className='submitBtn'>Submit</button>
                                </div>
                            </div>
                            <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>Or Saved Address:</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='address' /> Home</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='address' /> Work</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='address' /> Work 2</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-3 Profile'>
                        <div className='infoBox mt-0'>
                            <div className='row'>
                                <div className='col-8'>
                                    <label className='sideTitlep'>1 iPhone X Repair</label>
                                </div>
                                <div className='col-4 text-end'>
                                    <label className='price'>$ 60.00</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8'>
                                    <label className='sideTitlep'>Delivery</label>
                                </div>
                                <div className='col-4 text-end'>
                                    <label className='price'>$ 2.00</label>
                                </div>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-8'>
                                    <label className='sideTitlep red'>Total</label>
                                </div>
                                <div className='col-4 text-end'>
                                    <label className='price'>$ 62.00</label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='outBtn'>Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceDetails