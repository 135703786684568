import React from 'react'

const Returns = () => {
    return (
        <>
            <div className="container mt-60 Returns">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>RETURNS OR EXCHANGES</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <h1>Returns / Exchange</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <label className='returnTitle'>Returns</label>
                    </div>
                    <div className='col-10 m-auto'>
                        <hr />
                    </div>
                </div>
                <div className='row mb-120'>
                    <div className='col-12'>
                        <p className='text'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a lacus nulla. Proin elementum gravida feugiat. Nullam congue nisl purus, sit amet pretium turpis
                            aliquam tincidunt. Morbi tempor risus in urna luctus, non scelerisque mi iaculis. Donec metus diam, aliquet a ultrices in, congue id leo. Curabitur ac consectetur
                            nisl. Sed non ligula vel dui sagittis sodales. Aliquam erat volutpat. Mauris vestibulum erat leo, non condimentum nibh feugiat ultricies. Ut in turpis et mi fringilla
                            sodales. Ut eros magna, vulputate vel justo ut, feugiat egestas nunc. Sed tincidunt venenatis nisi, a ullamcorper est tincidunt vel.
                            <br/><br/>
                            1. lorem ipsum
                            <br/><br/>
                            2.lorem ipsum
                            <br/><br/>
                            3. lorem ipsum
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <label className='returnTitle'>Exchange</label>
                    </div>
                    <div className='col-10 m-auto'>
                        <hr />
                    </div>
                </div>
                <div className='row mb-120'>
                    <div className='col-12'>
                        <p className='text'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a lacus nulla. Proin elementum gravida feugiat. Nullam congue nisl purus, sit amet pretium turpis
                            aliquam tincidunt. Morbi tempor risus in urna luctus, non scelerisque mi iaculis. Donec metus diam, aliquet a ultrices in, congue id leo. Curabitur ac consectetur
                            nisl. Sed non ligula vel dui sagittis sodales. Aliquam erat volutpat. Mauris vestibulum erat leo, non condimentum nibh feugiat ultricies. Ut in turpis et mi fringilla
                            sodales. Ut eros magna, vulputate vel justo ut, feugiat egestas nunc. Sed tincidunt venenatis nisi, a ullamcorper est tincidunt vel.
                            <br/><br/>
                            1. lorem ipsum
                            <br/><br/>
                            2.lorem ipsum
                            <br/><br/>
                            3. lorem ipsum
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Returns