import React from 'react'
import { Link } from 'react-router-dom'
import {BsTelephoneFill} from 'react-icons/bs'
import { MdLocationOn, MdMail } from 'react-icons/md'

const CustomerService = () => {
    return (
        <>
            <div className="container mt-60 mb-50 Cservice">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>Customer Service</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <h1>Customer Service</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'>
                        <label className='CsTitle'>We're Here To Help</label>
                    </div>
                    <div className='col-9 m-auto'>
                        <hr />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <p className='text'>
                            We love hearing from our customers and we value your feedback. Send us your questions, comments or feedback so we can serve
                            you better.
                            <br /><br />
                            We receive many questions. Find answers to some of the most common ones in our
                            <Link to="/FAQs" className='red'> Faqs</Link> .
                            <br /><br />
                            <strong>Receipt Survey:</strong> If your receipt shows an invitation to complete a survey, please visit the URL shown on your receipt within two days of
                            your visit.
                        </p>
                    </div>
                </div>
                <div className='row mt-60'>
                    <div className='col-2'>
                        <label className='CsTitle'>Contact Us</label>
                    </div>
                    <div className='col-10 m-auto'>
                        <hr />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label className='send'>Send Us E-Mail Here:</label>
                    </div>
                </div>
                <div className='row mt-30'>
                    <div className='col-6'>
                        <div className='row'>
                            <div className='col-3'>
                                <label className='infoTitle'>Your Email</label>
                            </div>
                            <div className='col-9'>
                                <input className='inputBox' type="text" placeholder='Type Here'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-3'>
                                <label className='infoTitle'>Subject:</label>
                            </div>
                            <div className='col-9'>
                                <input className='inputBox' type="text" placeholder='Type Here'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-3'>
                                <label className='infoTitle'>Message:</label>
                            </div>
                            <div className='col-9'>
                                <input className='inputBox' type="text" placeholder='Type Here'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='sendBtn' type='button'>Send</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 ps-5'>
                        <div className='row '>
                            <div className='col-12 mb-20'>
                                <label className='text'><BsTelephoneFill /> +961 70 384 584</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mb-20'>
                                <label className='text'><MdMail /> info.playmegastore@gmail.com</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <label className='text'><MdLocationOn /> Verdun, main street next to Bimo Bank
                                <br/>Beirut, Lebanon</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerService