import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Global } from '../../Variables/Global';
import { BiChevronRight } from 'react-icons/bi'

const CategoryList = (props) => {
    const [inner, setInner] = useState(window.innerWidth);
    const [categories, setCategories] = useState([{
        CategoryId: 0,
        CategoryName: ""
    }])
    const navigate = useNavigate();

    const getCategories = async () => {
        const URL = Global.API_URL + "Category/GetCategories";
        await fetch(URL, {
            method: "Get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": Global.API_URL,
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
            })
            .then((result) => {
                setCategories(result)
            });
    }

    useEffect(() => {
        window.addEventListener("resize", function () {
            if (window.location.pathname.includes("/Category-List")) {
                if (inner > 767.98) {
                    navigate("/");
                }
            }
            setInner(window.innerWidth);
        });
        props.setHeaderTitle("Category List")
        props.setFooterActive(2);
        getCategories()
    }, [inner])
    return (
        <>
            <div className='container CategoryList'>
                {categories.map((c) => {
                    return (
                        <div className='row One' key={c.CategoryId}>
                            <div className='col-12'>
                                <Link to={'/Category/' + c.CategoryName.replace(/ /g, "-") + '/' + c.CategoryId}>
                                    <label className='name'>{c.CategoryName}</label>
                                    <span><BiChevronRight /></span>
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default CategoryList