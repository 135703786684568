import React from 'react'
import { Accordion } from 'react-bootstrap'

const FAQs = () => {
    return (
        <>
            <div className="container mt-60 Faqs">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>FAQS</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <h1>FAQS</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className='mb-50'>
                                <Accordion.Header>
                                    <span className='qNumber'>Q1: </span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum ex et rutrum auctor?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className='mb-50'>
                                <Accordion.Header>
                                <span className='qNumber'>Q2: </span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum ex et rutrum auctor?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className='mb-50'>
                                <Accordion.Header>
                                <span className='qNumber'>Q3: </span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum ex et rutrum auctor?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className='mb-50'>
                                <Accordion.Header>
                                <span className='qNumber'>Q4: </span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum ex et rutrum auctor?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className='mb-50'>
                                <Accordion.Header>
                                <span className='qNumber'>Q5: </span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum ex et rutrum auctor?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQs