import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MdComputer, MdOutlineLocalShipping } from 'react-icons/md'
import { FiClipboard, FiPackage } from 'react-icons/fi'
import { AiOutlineHome } from 'react-icons/ai'

const OrderTracking = (props) => {
    useEffect(() => {
        props.setHeaderTitle("Order Tracking")
        props.setFooterActive(3);
    }, [])
    
    return (
        <>
            <div className='container mt-60 Tracking'>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>Order Tracking</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <h1>order tracking</h1>
                    </div>
                </div>
                <div className='row mt-80'>
                    <div className='col-12'>
                        <div className='infoBox'>
                            <div className='row'>
                                <div className='col-6'>
                                    <p className='orderNumber'>
                                        ORDER #: <span className='red'>3</span>
                                        <Link to="#">View Details</Link>
                                    </p>
                                </div>
                                <div className='col-6 text-end'>
                                    <p className='expected'>
                                        Expected Arrival 01/06/20
                                        <br />
                                        Aramex <span className='red'>FGHGH34</span>
                                    </p>
                                </div>
                            </div>
                            <div className='row mt-80'>
                                <div className='col-12'>
                                    <div class="stepper-wrapper">
                                        <div class="stepper-item completed">
                                            <div class="step-counter"><MdComputer /></div>
                                            <div class="step-name">Record Made</div>
                                        </div>
                                        <div class="stepper-item completed">
                                            <div class="step-counter"><FiClipboard /></div>
                                            <div class="step-name">Order Processed</div>
                                        </div>
                                        <div class="stepper-item active">
                                            <div class="step-counter"><FiPackage /></div>
                                            <div class="step-name">Order Packaged</div>
                                        </div>
                                        <div class="stepper-item">
                                            <div class="step-counter"><MdOutlineLocalShipping /></div>
                                            <div class="step-name">Order On The Way</div>
                                        </div>
                                        <div class="stepper-item">
                                            <div class="step-counter"><AiOutlineHome /></div>
                                            <div class="step-name">Order Arrived</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-50'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Order Code:</label>
                                        </div>
                                        <div className='col-9'>
                                            <label className='orderInfo'>232-gho-8805</label>
                                        </div>
                                    </div>
                                    <div className='row mt-20'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Order Details:</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label className='orderInfo'>1  Macbook Air 13inch 256GB M1 Gold</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-10'>
                                                    <p className='orderText'>
                                                        Intel Core I5 1.1GHZ, 8GB ram LPDDR4, 512GB SSD Drive, Intel Iris Plus Graphics, 13.3" Retina 2560x1600, Touch ID
                                                    </p>
                                                </div>
                                                <div className='col-2 text-end'>
                                                    <label className='orderPrice'>$1,010.00</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-20'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Payment:</label>
                                        </div>
                                        <div className='col-9'>
                                            <label className='orderInfo text-uppercase'>Cash ON DELIVERY</label>
                                        </div>
                                    </div>
                                    <div className='row mt-20'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Estimated Time:</label>
                                        </div>
                                        <div className='col-9'>
                                            <label className='orderInfo text-uppercase'>3 days</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderTracking