import React, {useState} from 'react';
import activeWishListIcon from "../../images/icons/inWishList.png";
import wishListIcon from "../../images/icons/wishlist.png";
import { Global } from '../../Variables/Global';
import { Link, Navigate, useParams } from "react-router-dom";
import { useEffect } from 'react';



const Product = (props) => {
  
    return (
        <div className="col-6 col-md-4" key={props.itemkey}>
            <div className="item position-relative">
                <div className="imgBox">
                    {/* Image */}
                    {
                    props.product.DefaultVariation.Image2 == "" ? (         
                        <Link to={"/DetailedProduct/" + props.product.ProductName.replace(/ /g, "-") + "/" + props.product.ProductId + "/" + props.product.DefaultVariation.ProductVariationId}>
                        <img src={Global.PHOTO_URL + props.product.DefaultVariation.Image1} alt={props.product.ProductName} />
                        {/* Overlay "Out of Stock" label when out of stock */}
                        {!props.product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                    </Link>) : 
                    (
                        <Link to={"/DetailedProduct/" + props.product.ProductName.replace(/ /g, "-") + "/" + props.product.ProductId + "/" + props.product.DefaultVariation.ProductVariationId}>
                        <img src={Global.PHOTO_URL + props.product.DefaultVariation.Image1} alt={props.product.ProductName} 
                         onMouseOver={e => (e.currentTarget.src = Global.PHOTO_URL + props.product.DefaultVariation.Image2)}
                         onMouseOut={e => (e.currentTarget.src = Global.PHOTO_URL + props.product.DefaultVariation.Image1)}
                        />
                       
                        {!props.product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                    </Link>
                    )
                }
                </div>

                {/* Wishlist button */}
                {
                    props.WishListItems.find(obj => obj.ProductId === props.product.ProductId) ? (
                        <button className="btnWishList" onClick={() => props.HandleRemoveFromWishList(props.product.ProductId)}>
                            <img src={activeWishListIcon} alt="In WishList" />
                        </button>
                    ) : (
                        <button className="btnWishList" onClick={() => props.HandleAddToWishList(props.product.ProductId, props.product.DefaultVariation.ProductVariationId)}>
                            <img src={wishListIcon} alt="WishList" />
                        </button>
                    )
                }

                <div className="content">
                    {/* Product name */}
                    <Link to={"/DetailedProduct/" + props.product.ProductName.replace(/ /g, "-") + "/" + props.product.ProductId + "/" + props.product.DefaultVariation.ProductVariationId}>
                        <h5>{props.product.ProductName}</h5>
                    </Link>

                    {/* Price and add to cart */}
                    {
                        <>
                            {props.product.DefaultVariation.SalePrice === 0 ? (
                                <div>
                                    <div className="pricenormal">
                                        ${props.product.DefaultVariation.RegularPrice}{" "}
                                        <p className="price" style={{ minHeight: '50px' }}>
                                            <span className="off"></span>
                                            <span className="was"><span className="fw-medium"></span> <strike></strike></span>
                                        </p>
                                    </div>
                                    <div className="text-center">
                                        <Link to={"/DetailedProduct/" + props.product.ProductName.replace(/ /g, "-") + "/" + props.product.ProductId + "/" + props.product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <p className="price m-0">
                                        ${props.product.DefaultVariation.SalePrice}{" "}
                                    </p>
                                    <p className="price" style={{ minHeight: '50px' }}>
                                        <span className="off">-{props.product.DefaultVariation.Percentage.toFixed(2) + "%"}</span>
                                        <span className="was"><span className="fw-medium">Was:</span> <strike>${props.product.DefaultVariation.RegularPrice}</strike></span>
                                    </p>
                                    <div className="text-center">
                                        <Link to={"/DetailedProduct/" + props.product.ProductName.replace(/ /g, "-") + "/" + props.product.ProductId + "/" + props.product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </>
                    }
                </div>
            </div>
        </div>
    );
}



export default Product;